<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('sales_commission_details.sales_commission_details')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link to="/salestargetcommission/sales-commission-details" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{ $t('back') }}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>{{$t('sales_commission_details.employee')}}: {{data.employee_name}} #{{data.employee_id}}</div>
                            <div class="mt-5">
                                <table class="table text-center border-secondary">
                                    <thead>
                                        <tr>
                                            <th>{{$t('sales_commission_details.sales_amount')}}</th>
                                            <th>{{$t('sales_commission_details.invoice')}}</th>
                                            <th>{{$t('sales_commission_details.sales_quantity')}}</th>
                                            <th>{{$t('sales_commission_details.transaction_date')}}</th>
                                            <th>{{$t('sales_commission_details.total_commission')}}</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{data.sales_amount}}</td>
                                            <td>#{{data.invoice_id}}</td>
                                            <td>{{data.sales_quantity}}</td>
                                            <td>{{data.transaction_date}}</td>
                                            <td>{{data.total_commission}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-10">
                                <table class="table text-center border-secondary">
                                    <thead>
                                    <tr>
                                        <th>{{$t('sales_commission_details.sales_commission')}}</th>
                                        <th>{{$t('sales_commission_details.sales_period')}}</th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>#{{data.sales_commission_id}}</td>
                                        <td>#{{data.sales_period_id}}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-10">
                                <h5 class="mt-5">{{$t('sales_commission_details.list_of_the_items')}}</h5>
                                <table class="table table-row-bordered border-secondary">
                                    <thead>
                                    <tr>
                                        <th>{{$t('sales_commission_details.item')}}</th>
                                        <th>{{$t('sales_commission_details.unit_price')}}</th>
                                        <th>{{$t('sales_commission_details.qty')}}</th>
                                        <th>{{$t('sales_commission_details.subtotal')}}</th>
                                        <th>{{$t('sales_commission_details.sales_commission')}}</th>
                                        <th></th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    <tr v-for="(row, index) in data.items_list" :key="index">
                                        <td>
                                            {{row.item?row.item.name:''}}
                                        </td>
                                        <td>{{row.unit_price}}</td>
                                        <td>{{row.qty}}</td>
                                        <td>{{row.subtotal}}</td>
                                        <td>{{row.commission}}</td>

                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td class="border-0" colspan="3"></td>
                                        <th>{{$t('sales_commission_details.total')}}</th>
                                        <th><span>{{data.sales_amount}}</span></th>
                                    </tr>
                                    <tr>
                                        <td class="border-0" colspan="3"></td>
                                        <th>{{$t('sales_commission_details.total_quantity')}}</th>
                                        <th><span>{{data.sales_quantity}}</span></th>
                                    </tr>
                                    <tr>
                                        <td class="border-0" colspan="3"></td>
                                        <th>{{$t('sales_commission_details.sales_commission')}}</th>
                                        <th><span>{{data.total_commission}}</span></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>


                        </b-tab>
                        <b-tab :title="$t('activity_log.activity_log')">
                            <br>
                            <activity-log v-if="emp_id" :inner-key="key" :id="emp_id"></activity-log>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-sales-commission-details",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'salestargetcommission/sales-commission-details',
                id: this.$route.params.id? this.$route.params.id : null,
                emp_id: null,
                key: 'SalesPeriodReport',
                data: {},
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                    this.emp_id = response.data.data.employee_id;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_commission_details"), route:'/salestargetcommission/sales-commission-details'}, {title: this.$t('view')}]);
            if (this.id) {
                this.getData();
            }

        },
    };
</script>